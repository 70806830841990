/* General page container for the QRCodePage */
.qr-code-page {
    padding: 20px;
    margin: 20px auto;
    background-color: #000000; /* Black background for the entire page */
    color: #ffffff; /* White text */
    width: 100%; /* Full width of the content area */
    box-sizing: border-box; /* Include padding in width */
    text-align: center; /* Center-align all text and content */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.qr-code-page::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
}

/* Container for the QR code */
.qr-code-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #000000; /* Black background for container */
    margin-bottom: 2rem;
}

/* White background only around the QR code itself */
.qr-code-wrapper {
    background-color: #ffffff; /* White background for QR code */
    padding: 15px;
    border-radius: 10px; /* Optional: Add rounded corners */
    box-sizing: border-box;
    display: inline-block; /* Ensures the wrapper fits tightly around the QR code */
}

/* Image style for the QR code */
.qr-code-wrapper img {
    width: 250px;
    height: 250px;
    object-fit: cover;
    border-radius: 5px; /* Optional rounded corners for the QR code */
}

/* Download button styles */
.download-button {
    background-color: #4a8ad9; /* Primary blue color */
    color: white;
    border: none;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 1rem; /* Add space above the button */
}

.download-button:hover {
    background-color: #367bb5; /* Darker blue on hover */
}

/* Loader message or text style */
.loading-text {
    font-size: 1rem;
    color: #999; /* Light grey for loading text */
    text-align: center;
}

/* Header styles for QR Code page */
.qr-code-page h1 {
    font-size: 2rem;
    color: #4a8ad9; /* Primary color for the header */
    margin-bottom: 1.5rem;
}