/* Page container */
.faq-page {
    background-color: #000000; /* Black background */
    color: #fff; /* White text */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure footer stays at the bottom */
}

/* FAQ container for left-aligned content with a maximum width */
.faq-container {
    padding: 4rem;
    max-width: 800px; /* Contain the text within 800px */
    width: 100%; /* Ensure full width until max-width is reached */
    text-align: left; /* Ensure text is aligned left */
}

/* Text formatting */
.faq-container h1 {
    font-size: 2.5rem;
    margin-bottom: 4rem;
}

.faq-container h2 {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
}

.faq-container p {
    font-size: 1rem;
    margin-bottom: 3rem;
}

/* Header Styles */
.landing-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 2rem; /* Adjusted padding for uniformity */
    background-color: #000000; /* Black background for header */
    width: 100%;
    box-sizing: border-box;
}

/* Logo in the header */
.landing-header .logo {
    width: 130px;
    height: auto;
}

/* Right section for links and button */
.landing-header .header-right {
    display: flex;
    align-items: center;
    gap: 3rem;
}

/* Navigation Styles */
.landing-header nav ul {
    list-style: none;
    display: flex;
    gap: 1.5rem;
    padding: 0;
    margin: 0;
}

.landing-header nav ul li {
    display: inline-block;
}

.landing-header nav ul li a {
    color: #ffffff;
    text-decoration: none;
    font-size: 1rem;
}

.landing-header nav ul li a:hover {
    text-decoration: underline;
}

/* Footer */
.landing-footer {
    padding: 2rem;
    text-align: center;
    background-color: #000000; /* Black background for footer */
    color: #fff;
}

.landing-footer p {
    margin-bottom: 1rem;
}

.landing-footer nav ul {
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 0;
    margin: 0;
}

.landing-footer nav ul li a {
    color: #4a8ad9;
    text-decoration: none;
}

.landing-footer nav ul li a:hover {
    text-decoration: underline;
}