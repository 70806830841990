/* Login.css */

.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center content vertically */
    height: 100vh;
    background-color: #000000; /* Black background */
    text-align: center;
    color: white; /* Ensures text is visible on black background */
    margin: 0; /* Remove any default margins */
    padding: 0; /* Remove any default padding */
    box-sizing: border-box; /* Ensure padding doesn't affect layout */
  }
  
  .company-name-logo {
    width: 100%;
    max-width: 1200px; /* Increased max-width for larger image */
    height: auto;
    margin-bottom: 30px; /* Add space between the logo and the container */
  }
  
  h2 {
    margin: 20px 0;
    font-size: 24px;
    color: #ffffff; /* White text for contrast */
  }
  
  /* Container to make input fields and button the same width */
  .login-form-container {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
  }
  
  .login-input, .login-button {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box; /* Ensures padding doesn't affect the width */
  }
  
  .login-input {
    border: 1px solid #ccc;
    color: #000000; /* Black text inside input fields */
  }
  
  /* Change the outline color to blue when the input is focused */
  .login-input:focus {
    outline: 2px solid #4a8ad9; /* Blue outline */
  }

  .login-message {
    font-size: 14px;
    color: white;
  }

  .login-button {
    background-color: #4a8ad9;
    color: white;
    border: none;
    cursor: pointer;
    font-weight: bold;
  }
  
  .login-button:hover {
    background-color: #367bb5;
  }