/* Sidebar Styles */
.sidebar {
  width: 200px; /* Fixed width for the sidebar */
  background-color: #191919; /* Darker gray for the sidebar */
  padding: 20px;
  color: #FFFFFF; /* Ensure sidebar text is pure white */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the items horizontally */
  box-sizing: border-box; /* Include padding in the width */
  min-height: 100%; /* Ensure the sidebar takes full height of its parent container */
  flex-shrink: 0; /* Prevent the sidebar from shrinking */
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  width: 100%; /* Ensure the list takes the full width of the sidebar */
}

.sidebar ul li {
  margin-bottom: 10px;
  width: 100%; /* Ensure each list item takes the full width */
}

.sidebar ul li a {
  color: #FFFFFF; /* Pure white text for links */
  text-decoration: none;
  display: block;
  padding: 10px;
  border-radius: 4px;
  text-align: center; /* Center the text within each link */
  transition: background-color 0.3s;
}

.sidebar ul li a:hover {
  background-color: #4a8ad9; /* Slightly lighter shade for hover effect */
}

/* Dashboard Container */
.dashboard-container {
  display: flex;
  min-height: 100vh; /* Ensure the container takes at least full height */
  background-color: #000000; /* Set the entire background to black */
  color: #FFFFFF; /* Set default text color to pure white */
  width: 100%; /* Ensure the container takes the full width of the screen */
  box-sizing: border-box; /* Ensure padding and borders are included in the width */
  align-items: stretch; /* Make sure sidebar and content grow with container */
}

/* Dashboard Header */
.dashboard-header {
  text-align: left; /* Align text to the right */
  margin-bottom: 20px; /* Add some space below the header */
  color: #FFFFFF; /* Ensure header text is pure white */
  width: 100%; /* Ensure it takes the full width of its container */
  padding-right: 20px; /* Add some padding on the right for spacing */
  box-sizing: border-box; /* Include padding in width calculation */
}

/* Dashboard Content Wrapper */
.dashboard-content-wrapper {
  flex-grow: 1; /* Allow wrapper to take up remaining space */
  display: flex;
  width: calc(100% - 200px); /* Account for the sidebar width */
  box-sizing: border-box; /* Include padding and borders in the width */
}

/* Dashboard Content */
.dashboard-content {
  flex-grow: 1;
  padding: 20px;
  background-color: #000000; /* Set content background to black */
  color: #FFFFFF; /* Ensure content text is pure white */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  align-items: flex-start; /* Align content to the left */
  min-height: 100%; /* Make content take full height of the parent container */
  width: 100%; /* Ensure content takes full width of its wrapper */
  box-sizing: border-box; /* Ensure padding and borders are included in the width */
}