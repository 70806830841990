/* Minimalist Approach */

/* General container for the Device Management page */
.device-management-container {
    max-width: 1000px;
    margin: 20px auto;
    padding: 20px;
    background-color: #000000; /* Black background */
    color: #ffffff; /* White text */
  }
  
  /* Header styles */
  .device-management-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #4a8ad9; /* Main color for headers */
  }
  
  /* Subheader styles */
  .device-management-container h3 {
    margin-bottom: 15px;
    font-size: 20px;
    color: #4a8ad9; /* Main color for subheaders */
    text-align: center; /* Center the text */
  }
  
  /* Styles for the form section */
  .device-management-form {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next line if necessary */
    gap: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    align-items: center; /* Align items to the center vertically */
  }

  .device-management-form input,
  .device-management-form button,
  .device-management-form .product-image-button-add {
    padding: 10px;
    font-size: 14px; /* Slightly smaller font size to fit content */
    border: 1px solid #4a8ad9; /* Main color for borders */
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #ffffff; /* White background for inputs */
    color: black; /* Black text inside inputs */
    height: 40px; /* Ensure consistent height */
    line-height: normal; /* Ensure consistent vertical alignment */
    text-align: center; /* Center text inside the label */
    cursor: pointer; /* Pointer cursor for the label button */
    flex: 1; /* Allow each item to grow equally */
    min-width: 120px; /* Set a minimum width for each item */
  }

  
  .device-management-form input::placeholder {
    color: black; /* Black placeholder text */
  }
  
  /* Hide the actual file input */
  .device-management-form .product-image {
    display: none;
  }
  
  .device-management-form button {
    height: 40px;
    background-color: #4a8ad9; /* Main color for button */
    font-weight: bold;
    color: #ffffff; /* White text on button */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 0; /* Remove extra margin */
  }
  
  .device-management-form button:hover,
  .device-management-form .product-image-button:hover {
    background-color: #357abd;
    color: #ffffff; /* White text on hover */
  }
  
  /* Message styling */
  .device-management-message {
    text-align: center;
    font-size: 14px;
    color: #FFFFFF; /* Error color, can adjust for success */
    margin-bottom: 20px;
  }

  .html-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff; /* White text */
    background-color: #4a8ad9; /* Blue background */
    border: none;
    border-radius: 4px;
    text-decoration: none; /* Remove underline from links */
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .html-button:hover {
    background-color: #357abd; /* Darker blue on hover */
  }
  
  








.product-list {
  padding: 0;
  margin: 0;
}

.product-list-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr)); /* Ensure each column is at least 320px wide */
  justify-content: center; /* Center the grid within its container */
  padding: 0;
  margin: 0;
  list-style-type: none; /* Remove bullet points */
  gap: 15px; /* Space between the grid items */
  
  /* Override default browser styles for ul */
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

.product-list-item {
  position: relative;
  width: 320px; /* Fixed width */
  height: 480px; /* Fixed height */
  background: #191919; /* Slightly lighter background */
  border-radius: 20px;
  overflow: hidden;
  border: 3px solid white; /* Adds a solid white border */
  display: flex;
  box-sizing: border-box;
  margin: auto; /* Center each item horizontally within its grid cell */
  padding: 0; /* Remove any padding from the outer container */
  z-index: 1; /* Ensure content is above the background */
}

.product-list-item::before {
  content: "";
  position: absolute;
  top: -50%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #4a8ad9; /* Blue background */
  transform: skewY(345deg);
  transform-origin: top left; /* Skew relative to the top-left corner */
  z-index: -1; /* Ensure the blue background is behind the content */
}

.product-card-name {
  font-size: 1.5rem; /* Larger font size for the product name */
  font-weight: bold; /* Make the product name bold */
  margin-bottom: 10px; /* Add space below the product name */
}

.product-actions {
  display: flex;
  justify-content: space-between; /* Spread the left and right buttons */
  align-items: center;
  position: relative; /* Set a relative position to the parent container */
  width: 100%; /* Ensure the container takes up full width */
  margin-bottom: 15px; /* Space between actions and image */
}

.edit-button {
  flex: 0; /* Keep the button on the left */
  font-size: 16px;
}

.activate-button {
  position: absolute;
  left: 50%; /* Position the button horizontally at the center */
  transform: translateX(-50%); /* Correct for half the width of the button to perfectly center */
  font-size: 16px;
}

.delete-button {
  flex: 0; /* Keep the button on the right */
}

.product-list-content {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: space-between; /* Space out children */
  align-items: center; /* Center children horizontally */
  width: 100%;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
}

.product-image-wrapper {
  margin-bottom: 15px; /* Space between image and info */
}

.product-image {
  border-radius: 4px;
  max-width: 220px;
  max-height: 220px;
}

.edit-product-image {
  display: none;
  font-weight: bold;
}

.product-info {
  color: #FFFFFF; /* Ensure text color is white */
  text-align: center; /* Center text inside the product card */
  margin-bottom: 20px; /* Space at the bottom of the product info */
  white-space: nowrap;
}

.product-details {
  font-size: 1rem; /* Regular font size for other product details */
}

.delete-button {
  background: none; /* No background color */
  color: #FFFFFF; /* Red color for the X */
  border: none; /* Remove the border */
  cursor: pointer; /* Keep pointer cursor for interaction */
  padding: 5px 10px;
  font-size: 20px; /* Slightly larger font for the X */
}

/* Ensure no hover effect for .delete-button */
.delete-button:hover {
  background: none !important; /* No background on hover */
  color: #B0B0B0 !important; /* Maintain the same color on hover */
}


.product-list button,
.edit-button,
.update-button,
.cancel-button {
  background-color: #4a8ad9; /* Main color for buttons */
  color: #ffffff; /* White text */
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px 10px;
  transition: background-color 0.3s;
}

.product-list button:hover,
.edit-button:hover,
.update-button:hover,
.cancel-button:hover {
  background-color: #357abd; /* Darker shade on hover */
}

/* Styles for the edit form */
.product-edit-form {
  padding: 20px; /* Increase padding for more space inside the item */
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Consistent shadow */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content vertically and horizontally */
  justify-content: center; /* Center content vertically */
  width: 100%; /* Ensure item takes full width of its container */
  box-sizing: border-box;
  margin: 10px; /* Add space around each product list item */
}

.edit-input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #4a8ad9;
  border-radius: 4px;
  background-color: #ffffff;
  color: black;
  box-sizing: border-box;
}

.product-image-button-add {
  background-color: #4a8ad9;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
}

.product-image-button-edit {
  padding: 10px;
  background-color: #4a8ad9;
  color: #ffffff;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 10px; /* Add space below the button */
}

.product-edit-actions {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  gap: 10px; /* Add space between the buttons */
}



.message-popup {
  position: fixed;
  top: 20px;
  right: 30px;
  background-color: #4a8ad9;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 9999;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transition: opacity 2s ease-in-out; /* Slower fade-in/out effect */
  pointer-events: none;
}

.message-popup-hidden {
  opacity: 0; /* Hidden state with 0 opacity */
}