/* General container for the RentalInformation page */
.rental-information-container {
    padding: 20px;
    margin: 20px auto;
    background-color: #000000; /* Black background */
    color: #ffffff; /* White text */
    width: 100%; /* Ensure the container takes full width of the content area */
    box-sizing: border-box; /* Include padding in width */
    overflow-x: auto; /* Allow horizontal scrolling if needed */
    scrollbar-width: none; /* Hide scrollbar in Firefox */
    -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer and Edge */
}

.rental-information-container h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #4a8ad9; /* Main color for headers */
  }

.rental-information-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar in WebKit browsers (Chrome, Safari) */
}

/* Table styles */
.rental-information-container table {
    width: 100%; /* Ensure the table takes full width of the container */
    border-collapse: collapse;
    background-color: #FFFFFF; /* Slightly lighter black for table background */
    margin: 0; /* Remove any default margin */
}

.rental-information-container th, .rental-information-container td {
    padding: 12px 15px;
    border: 1px solid #4a8ad9; /* Main color for borders */
}

/* Center the table headers */
.rental-information-container th {
    background-color: #4a8ad9; /* Main color for header */
    color: #ffffff; /* White text in header */
    text-align: center; /* Center the header text */
    border-right: 1px solid #000000; /* Black line between each header column */
}

/* Remove the right border from the last header to avoid double border */
.rental-information-container th:last-child {
    border-right: none;
}

/* Align the table cells' text to the left, but you can change it if needed */
.rental-information-container td {
    color: #000000; /* White text in table cells */
    text-align: left; /* Default to left-align, change if needed */
}

/* Loading and error message styles */
.loading, .error {
    text-align: left;
    font-size: 16px;
    margin: 20px 0;
}

.error {
    color: #FFFFFF; /* Error color */
}

 