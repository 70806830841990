/* Page container */
.contact-page {
    background-color: #000000; /* Black background */
    color: #fff; /* White text */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure footer stays at the bottom */
}

/* Contact container for content alignment */
.contact-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align content to the left */
    text-align: left; /* Left-align text */
    padding: 4rem;
    width: 100%;
}

.contact-container h1 {
    font-size: 2.5rem;
}

.contact-container p {
    font-size: 1.2rem;
    margin-top: 0.2rem;
}

.contact-info {
    font-size: 1.2rem;
    margin-bottom: 0.15rem;
    line-height: 1.2; /* Adjust this value to make line spacing tighter */
}

/* Contact form styles */
.contact-form {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    width: 100%;
    max-width: 500px; /* Limit max-width */
}

/* Form layout for two-column rows */
.contact-form-row {
    display: flex;
    justify-content: space-between; /* Ensure equal space between first and last name */
    gap: 3rem; /* Space between the two fields */
    width: 100%; /* Full width of the form container */
}

.contact-form-group {
    flex-basis: 48%; /* Ensure each field takes 48% of the row width */
    display: flex;
    flex-direction: column;
    margin-bottom: 1.2rem;
}

/* Input fields with placeholders */
.contact-form-group input, .contact-form-group textarea {
    width: 100%; /* Ensure inputs take up full width of their container */
    padding: 1rem; /* Add padding for a box-like feel */
    border-radius: 0.2rem;
    border: none; /* Remove borders */
    background-color: #1a1a1a; /* Dark background for the input fields */
    color: #fff; /* White text inside the input fields */
    font-size: 1rem;
    transition: background-color 0.3s, color 0.3s;
}

/* Placeholder styling */
.contact-form-group input::placeholder, .contact-form-group textarea::placeholder {
    color: #aaa; /* Light gray for placeholder text */
    font-size: 1rem;
}

/* Input focus styling */
.contact-form-group input:focus, .contact-form-group textarea:focus {
    outline: none; /* Remove default outline */
    background-color: #1a1a1a; /* Keep dark background */
    color: #fff; /* White text on focus */
}

/* Remove text highlighting on input when selected */
.contact-form-group input::selection, .contact-form-group textarea::selection {
    background: #444; /* Darker gray background for selected text */
    color: #fff; /* White text on selection */
}

/* Submit button */
.contact-submit-button {
    background-color: #4a8ad9;
    color: #fff;
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 0.2rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s;
    width: 120px; /* Change this value to whatever width you prefer */
    text-align: center; /* To center the text inside the button */
}

.contact-submit-button:hover {
    background-color: #367bb5;
}

/* Header Styles */
.landing-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 2rem; /* Adjusted padding for uniformity */
    background-color: #000000; /* Black background for header */
    width: 100%;
    box-sizing: border-box;
}

/* Logo in the header */
.landing-header .logo {
    width: 130px;
    height: auto;
}

/* Right section for links and button */
.landing-header .header-right {
    display: flex;
    align-items: center;
    gap: 3rem;
}

/* Navigation Styles */
.landing-header nav ul {
    list-style: none;
    display: flex;
    gap: 1.5rem;
    padding: 0;
    margin: 0;
}

.landing-header nav ul li {
    display: inline-block;
}

.landing-header nav ul li a {
    color: #ffffff;
    text-decoration: none;
    font-size: 1rem;
}

.landing-header nav ul li a:hover {
    text-decoration: underline;
}

/* Footer */
.landing-footer {
    padding: 2rem;
    text-align: center;
    background-color: #000000; /* Black background for footer */
    color: #fff;
}

.landing-footer p {
    margin-bottom: 1rem;
}

.landing-footer nav ul {
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 0;
    margin: 0;
}

.landing-footer nav ul li a {
    color: #4a8ad9;
    text-decoration: none;
}

.landing-footer nav ul li a:hover {
    text-decoration: underline;
}
