/* General Styles */
html, body {
  overflow: auto; /* Keep scrolling functionality */
  scrollbar-width: none; /* For Firefox */
}

html::-webkit-scrollbar, body::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}



.landing-page {
  font-family: 'Roboto', sans-serif;
  color: #ffffff; /* White text for dark background */
  margin: 0;
  padding: 0;
  background-color: #000; /* Black background */
}

/* Header Styles */
.landing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #000000; /* Black background */
  border-bottom: 1px solid #333;
  position: sticky;
  top: 0;
  z-index: 100;
}

.logo {
  width: 150px;
  height: auto;
}

.header-right {
  display: flex;
  align-items: center;
}

.header-right nav ul {
  list-style: none;
  display: flex;
  gap: 1.5rem;
  margin: 0;
}

.header-right nav ul li a {
  text-decoration: none;
  color: #ffffff; /* White text */
  font-weight: 500;
  transition: color 0.3s;
}

.header-right nav ul li a:hover {
  color: #ffffff;
}

.login-button-horizontal {
  width: 8em; /* Reduce width */
  height: 2.5em; /* Reduce height */
  border: 2px ridge #4a8ad9; /* Adjust border size */
  outline: none;
  background-color: transparent;
  color: white;
  transition: 1s;
  border-radius: 0.3em;
  font-size: 14px; /* Reduce font size */
  font-weight: bold;
  cursor: pointer;
}


.login-button-horizontal:hover {
  box-shadow: inset 0px 0px 20px #367bb5; /* Slightly reduce shadow for consistency */
}




/* Hero Section */
.hero-section {
  background-image: url('../img/bg.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  color: #ffffff;
  text-align: center;
  padding: 6rem 2rem;
}

.hero-section::before {
  content: '';
  background-color: rgba(0, 0, 0, 0.5); /* Overlay for text readability */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hero-content {
  position: relative;
  z-index: 1;
}

.title-company-logo {
  width: 1000px;
  height: auto;
  margin-bottom: 1rem;
}

.hero-section p {
  font-size: 1.5rem;
  max-width: 600px;
  margin: 0 auto 2rem;
}

.cta-button {
  padding: 0.75rem 1.5rem;
  background-color: #4a8ad9;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.cta-button:hover {
  background-color: #367bb5;
}

/* Features Section */
.features-section {
  padding: 4rem 2rem;
  text-align: center;
  background-color: #000000; /* Black background */
}

.features-section h2 {
  font-size: 2rem;
  margin-bottom: 3rem;
  color: #ffffff; /* White text */
}

.features-grid {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.feature-item {
  background-color: #ffffff; /* Dark gray background for contrast */
  border: 1px solid #333;
  border-radius: 10px;
  padding: 2rem;
  max-width: 350px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.feature-item img {
  width: 100%;
  height: 200px; /* Set a fixed height for images */
  object-fit: contain; /* Scale the image to fit within the box without cutting sides */
  margin-bottom: 1.5rem;
}

.feature-item h3 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: #4a8ad9;
}

.feature-item p {
  font-size: 1rem;
  color: #000000; /* Light gray text */
  flex-grow: 1; /* Ensure the paragraph fills the remaining space */
  display: flex;
  align-items: flex-end; /* Aligns text to the bottom of the box */
}


/* Cards Section */
.cards-section {
  padding: 4rem 2rem;
  text-align: center;
  background-color: #000000; /* Slightly lighter black for contrast */
}

.cards-section h2 {
  font-size: 2rem;
  margin-bottom: 3rem;
  color: #ffffff;
}

.cards-grid {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.card-item {
  background-color: #ffffff;
  border: 1px solid #333;
  border-radius: 10px;
  padding: 2rem;
  max-width: 300px;
  text-align: center;
}

.card-item img {
  width: 60%;
  height: auto;
  margin-bottom: 1rem;
}

.card-item h3 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: #4a8ad9;
}

.card-item p {
  font-size: 1rem;
  color: #000000;
}

/* Split Section */
.split-section {
  display: flex;
  align-items: center;
  padding: 4rem 2rem;
  background-color: #4a8ad9; /* Black background */
}

.split-content {
  flex: 1;
  padding-right: 2rem;
}

.split-content h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #ffffff;
}

.split-content p {
  font-size: 1rem;
  color: #ffffff;
  margin-bottom: 2rem;
}

.split-image {
  flex: 1;
  text-align: center;
  overflow: hidden; /* Ensures the image doesn't overflow the container */
  max-width: 60%; /* Restrict the width of the image container */
  max-height: 60%; /* Restrict the height of the image container */
}

.split-image img {
  width: 70%;
  height: auto;
  border-radius: 10px;
  object-fit: contain; /* Ensures the image scales to fit within the container */
}


/* CTA Section */
.cta-section {
  background-color: #4a8ad9; /* Blue background */
  color: #fff;
  padding: 4rem 2rem;
  text-align: center;
}

.cta-section h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.cta-section p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.cta-section .cta-button {
  background-color: #fff; /* White button */
  color: #4a8ad9;
  font-weight: bold;
}

.cta-section .cta-button:hover {
  background-color: #eaeaea;
}

/* Footer Styles */
.landing-footer {
  background-color: #000; /* Black background */
  color: #fff;
  padding: 2rem;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
}

.landing-footer p {
  margin-bottom: 1rem;
}

.landing-footer nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  padding: 0;
  margin: 0;
}

.landing-footer nav ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 0.9rem;
}

.landing-footer nav ul li a:hover {
  color: #4a8ad9;
}
