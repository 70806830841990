/* Page container */
.landing-page {
    background-color: #000000; /* Black background */
    color: #fff; /* White text */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure footer stays at the bottom */
  }
  
  /* Header Styles */
  .landing-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 2rem; /* Adjusted padding for uniformity */
    background-color: #000000; /* Slightly lighter black for header */
    width: 100%;
    box-sizing: border-box;
  }
  
  /* Left section for the logo */
  .landing-header .logo {
    width: 130px;
    height: auto;
  }
  
  /* Right section for links and button */
  .landing-header .header-right {
    display: flex;
    align-items: center;
    gap: 3rem;
  }
  
  /* Navigation Styles */
  .landing-header nav ul {
    list-style: none;
    display: flex;
    gap: 1.5rem;
    padding: 0;
    margin: 0;
  }
  
  .landing-header nav ul li {
    display: inline-block;
  }
  
  .landing-header nav ul li a {
    color: #ffffff;
    text-decoration: none;
    font-size: 1rem;
  }
  
  .landing-header nav ul li a:hover {
    text-decoration: underline;
  }

  
  /* Footer */
  .landing-footer {
    padding: 2rem;
    text-align: center;
    background-color: #000000;
  }
  
  .landing-footer p {
    margin-bottom: 1rem;
  }
  
  .landing-footer nav ul {
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 0;
    margin: 0;
  }
  
  .landing-footer nav ul li a {
    color: #4a8ad9;
    text-decoration: none;
  }
  
  .landing-footer nav ul li a:hover {
    text-decoration: underline;
  }
  



/* Parent container */
.store-main {
  background-color: #000000;
  display: flex; /* Use flexbox */
  align-items: center; /* Center vertically */
  min-height: 100vh; /* Full height of the viewport */
  flex-direction: column; /* Stack items vertically */
  padding-top: 50px; /* Add space at the top of the page */
}

/* Center the product container */
.store-product-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  background-color: #000000;
  padding: 5px;
  box-sizing: border-box;
  width: 100%;
  max-width: 800px; /* Constrain the width */
  margin-bottom: 20px; /* Add some spacing if necessary */
}



/* Product card */
.store-product-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 800px;
    background-color: #fff;
    border: 2px solid black;
    border-radius: 7px;
    box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    margin: 10px;
    box-sizing: border-box;
}

/* Product image */
.store-product-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    background: linear-gradient(25deg, black 50%, #4a8ad9 50%);
    border: 2px solid white;
    border-radius: 7px;
    box-sizing: border-box;
}

.store-product-img img {
    width: 100%;
    max-width: 200px;
    height: auto;
    object-fit: cover;
    margin: 10px;
    box-sizing: border-box;
}

/* Product info */
.store-product-info {
    width: 50%;
    padding: 20px;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    overflow-wrap: break-word;
    color: #000; /* Change to black text */
    white-space: normal; /* Allow text to wrap */
    text-align: left; /* Ensure text aligns properly */
}

/* Product text */
.store-product-text {
    margin-bottom: 20px;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.store-product-text h1 {
    margin: 0;
    font-size: 1.5em;
    color: black;
}

.store-product-text p {
    margin-top: 10px;
    font-size: 1em;
    color: black;
    line-height: 1.7em;
}

/* Product price and button */
.store-product-price-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.store-product-price-btn p {
    margin: 0;
    font-size: 1.25em;
    color: black;
}

.store-product-price-btn button {
    font-size: 1.2em;
    color: #fff;
    background-color: #4a8ad9;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

.store--price-btn button:hover {
    background-color: #333;
}



@media (max-width: 650px) {
    .store-product-container {
        flex-direction: column;
        align-items: center;
        left: 0;
        max-width: 100%;
    }

    .store-product-card {
        flex-direction: column;
        max-width: 92%;
    }

    .store-product-img, .store-product-info {
        width: 100%;
        text-align: center;
    }

    .store-product-img img {
        height: auto;
        width: 100%; /* Ensures the image scales with the container width */
        max-width: 50%; /* Adjust as needed for smaller screens */
        margin-top: 10px;
        margin-bottom: 10px;
        box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
    }
    
}


#customButton {
    background-color: #4a8ad9; /* PayPal blue color */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    white-space: nowrap;
    border: 1px solid black;
}

#customButton:hover {
    background-color: #85b2eb; /* Darker blue on hover */
}
