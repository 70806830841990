/* Back button styling */
.back-button-container {
  background-color: #ffffff;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 15px; /* Ensure spacing between button and header */
  position: relative;
}

.back-button {
  background-color: transparent;
  border: none;
  color: #000000;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.back-button:hover {
  color: #4a8ad9;
}

/* Center container for the entire page */
.payment-center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  min-height: 100vh;
  background-color: #000000;
  padding: 20px;
  box-sizing: border-box;
}

/* Card container */
.contentBox {
  background-color:#ffffff;
  padding: 30px;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  box-sizing: border-box;
  margin-bottom: 20px;
  position: relative;
}

/* Form header styling */
.form-header {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center; /* Center content horizontally */
  margin-bottom: 20px; /* Adjust to ensure proper spacing between button and header */
}

.form-header h3 {
  margin: 0;
  color: #000000;
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
}

.center-header {
  text-align: center;
}

/* Input field styling */
.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  color: #333333;
  font-size: 1rem;
  box-sizing: border-box;
}

.input-field::placeholder {
  color: #999999;
}

/* Stripe Card Element styling */
.card-element {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  margin-bottom: 20px;
  box-sizing: border-box;
}

/* Submit button styling */
.submit-button, .create-intent-button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  background-color: #4a8ad9;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:disabled, .create-intent-button:disabled {
  background-color: #666666;
  cursor: not-allowed;
}

.submit-button:hover:not(:disabled), .create-intent-button:hover:not(:disabled) {
  background-color: #367bb5;
}

/* Error message styling */
.error-message {
  color: #FFFFFF;
  margin-top: 15px;
  font-size: 0.9rem;
}
