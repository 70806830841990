/* Minimalist Approach */

/* General container for the UserSettings page */
.user-settings-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #000000; /* Black background */
    color: #ffffff; /* White text */
}

/* Header styles */
.user-settings-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #4a8ad9; /* Main color for headers */
}

/* Subheader styles */
.user-settings-container h3 {
    margin-bottom: 15px;
    font-size: 20px;
    color: #4a8ad9; /* Main color for subheaders */
    grid-column: span 2; /* Span across both columns */
    text-align: center; /* Center the text */
}

/* Styles for the form section */
.user-settings-form {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns */
    gap: 15px;
    margin-bottom: 20px;
    align-items: start; /* Align items to the start */
}

.user-settings-form input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #4a8ad9; /* Main color for borders */
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #ffffff; /* White background for inputs */
    color: black; /* Black text color inside inputs */
}

.user-settings-form input::placeholder {
    color: black; /* Black placeholder color */
}

/* Specific placement for First Name and Last Name */
.user-settings-form .first-name {
    grid-column: 1 / 2; /* Place in the first column */
}

.user-settings-form .last-name {
    grid-column: 2 / 3; /* Place in the second column */
}

/* Make the email input span both columns */
.user-settings-form input[type="email"] {
    grid-column: span 2; /* Span across both columns */
}

/* Button styling */
.user-settings-form button {
    grid-column: span 2;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    background-color: #4a8ad9; /* Main color for button */
    color: #ffffff; /* White text color on button */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.user-settings-form button:hover {
    background-color: #357abd;
    color: #ffffff; /* Keep white text color on hover */
}

/* Message styling */
.user-settings-message {
    text-align: center;
    font-size: 14px;
    color: #FFFFFF; /* Error color, can adjust for success */
    margin-bottom: 20px;
}

.message-popup {
    position: fixed;
    top: 20px;
    right: 30px;
    background-color: #4a8ad9;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 9999;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    opacity: 1;
    transition: opacity 2s ease-in-out; /* Slower fade-in/out effect */
    pointer-events: none;
  }
  
  .message-popup-hidden {
    opacity: 0; /* Hidden state with 0 opacity */
  }
  