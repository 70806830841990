/* Center container for the entire page */
.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    min-height: 100vh;
    background-color: #000000;
    padding: 20px;
    box-sizing: border-box;
  }
  
  /* Card container */
  .contentBox {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    text-align: center;
    box-sizing: border-box;
    margin-bottom: 20px;
    position: relative;
  }
  
  /* Spinner container styling (center spinner within the white box) */
  .spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
  }
  
  /* Spinner styling */
  .container {
    --uib-size: 40px;
    --uib-color: #4a8ad9; /* Spinner color */
    --uib-speed: 0.9s;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--uib-size);
    width: var(--uib-size);
  }
  
  .dot {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }
  
  .dot::before {
    content: '';
    height: 20%;
    width: 20%;
    border-radius: 50%;
    background-color: var(--uib-color); /* Apply blue color to dots */
    transform: scale(0);
    opacity: 0.5;
    animation: pulse calc(var(--uib-speed) * 1.111) ease-in-out infinite;
    transition: background-color 0.3s ease;
  }
  
  /* Dot rotation for spinner */
  .dot:nth-child(2) { transform: rotate(45deg); }
  .dot:nth-child(2)::before { animation-delay: calc(var(--uib-speed) * -0.875); }
  .dot:nth-child(3) { transform: rotate(90deg); }
  .dot:nth-child(3)::before { animation-delay: calc(var(--uib-speed) * -0.75); }
  .dot:nth-child(4) { transform: rotate(135deg); }
  .dot:nth-child(4)::before { animation-delay: calc(var(--uib-speed) * -0.625); }
  .dot:nth-child(5) { transform: rotate(180deg); }
  .dot:nth-child(5)::before { animation-delay: calc(var(--uib-speed) * -0.5); }
  .dot:nth-child(6) { transform: rotate(225deg); }
  .dot:nth-child(6)::before { animation-delay: calc(var(--uib-speed) * -0.375); }
  .dot:nth-child(7) { transform: rotate(270deg); }
  .dot:nth-child(7)::before { animation-delay: calc(var(--uib-speed) * -0.25); }
  .dot:nth-child(8) { transform: rotate(315deg); }
  .dot:nth-child(8)::before { animation-delay: calc(var(--uib-speed) * -0.125); }
  
  @keyframes pulse {
    0%, 100% {
      transform: scale(0);
      opacity: 0.5;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  /* Loading message styling */
  .loading-message {
    margin-top: 20px;
    font-size: 1rem;
    color: black;
    text-align: center;
  }
  
  /* Form header styling */
  .form-header {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .form-header h3 {
    margin: 0;
    color: #000000;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  /* Button styling */
  .blue-button {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 4px;
    background-color: #4a8ad9; /* Blue button color */
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  }
  
  .blue-button:disabled {
    background-color: #666666; /* Gray out when disabled */
    cursor: not-allowed;
  }
  
  .blue-button:hover:not(:disabled) {
    background-color: #367bb5; /* Blue hover color */
  }