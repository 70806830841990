/* General container styling */
.device-setup-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align items to start and expand down */
    min-height: 100vh; /* Ensure the container takes up full viewport height */
    width: 100%; /* Take up full width of the page */
    background-color: #000000; /* Match the black background */
    padding: 20px;
    box-sizing: border-box; /* Include padding in the width calculation */
  }
  
  /* Box containing the setup instructions and images */
  .device-setup-box {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    background-color: #000000; /* Match the black background */
    border-radius: 8px;
    width: 100%; /* Take full width */
    max-width: 1000px; /* Limit the maximum width */
    padding: 20px; /* Padding inside the box */
    box-sizing: border-box; /* Include padding in width */
  }
  
  /* Center the header only */
  .device-setup-header {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #4a8ad9; /* White text for contrast */
  }
  
  /* Content section with text and images */
  .device-setup-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px; /* Space between text and images */
  }
  
  /* Text column */
  .device-setup-text {
    flex: 1;
    padding: 20px;
    text-align: left; /* Align the rest of the text to the left */
    color: #ffffff; /* White text for contrast */
  }
  
  /* Center the introduction text */
  .device-setup-intro {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #ffffff; /* White text */
  }
  
  /* Center the support button */
  .device-setup-support {
    text-align: center;
    margin-top: 20px;
    width: 100%; /* Ensure the button is centered within the full component */
  }
  
  .device-setup-text ol {
    margin-top: 20px;
    padding-left: 20px;
    color: #ffffff; /* Slightly dimmed white for list items */
  }
  
  .device-setup-text ol li {
    margin-bottom: 15px;
    font-size: 1.2rem;
  }
  
  .device-setup-text p {
    margin-top: 20px;
    font-size: 1rem;
    color: #ffffff;
  }
  
  .device-setup-text a.support-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #4a8ad9;
    color: #ffffff;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .device-setup-text a.support-link:hover {
    background-color: #0056b3;
  }
  
  /* Images column */
  .device-setup-images {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .device-setup-images img {
    width: 80%; /* Fixed width for images */
    margin: 20px 0;
    border-radius: 8px;
  }